import React, { useEffect } from 'react'

function FullScreen({ children }) {
  useEffect(() => {
    const doc = document.getElementsByTagName('html')[0]
    doc.style['overflow-y'] = 'hidden'
    return () => {
      doc.style['overflow-y'] = 'scroll'
    }
  }, [])

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default FullScreen
