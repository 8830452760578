import { Button } from 'antd'
import React, { useMemo } from 'react'
import useWebsocket from '../../../hooks/useWebsocket'
import FullScreen from '../../../shared/FullScreen'
import { useMyContext } from '../../../context/context'
import { useUpdateLiveMutation, useUpdateMessageMutation } from '../../../api/live'

export default function LiveManager() {
  const [data] = useWebsocket(true)
  const { settings, myContext } = useMyContext()

  const [updateLive] = useUpdateLiveMutation()
  const [updateMessage] = useUpdateMessageMutation()
  console.log(data)

  const messages = useMemo(
    () => data?.messages?.filter(f => f.favorite && !f.archived).map(m => ({ ...m, active: m.id === data?.message?.id })),
    [data]
  )

  const current = useMemo(() => messages?.find(f => f.active), [messages])

  const show = messageId => {
    updateLive({ ...myContext, live: { activeMessageId: messageId } })
    if (current && messageId) {
      updateMessage({ ...myContext, messageId: current.id, message: { archived: true } })
    }
  }

  return (
    <FullScreen>
      <div
        style={{
          background: settings?.bgPrimaryColor,
          color: settings?.textPrimaryColor,
          textAlign: 'center',
          padding: 16,
          marginBottom: 16,
          fontSize: 20,
        }}
      >
        {messages?.length || 0} questions à traiter
      </div>

      <div style={{ padding: '0px 16px', display: 'flex', flexDirection: 'column', gap: 32 }}>
        {messages?.map(m => (
          <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
            <div
              style={{
                flexGrow: 1,
                border: `2px solid ${settings?.bgPrimaryColor}`,
                padding: 8,
                textAlign: 'center',
                ...(m.active ? { background: settings?.bgPrimaryColor, color: settings?.textPrimaryColor } : {}),
              }}
            >
              {m.message}
            </div>
            {m.active ? (
              <Button onClick={() => show(null)}>Masquer</Button>
            ) : (
              <Button type="primary" onClick={() => show(m.id)}>
                Afficher
              </Button>
            )}
          </div>
        ))}
      </div>
    </FullScreen>
  )
}
