/* eslint-disable react/jsx-props-no-spreading */
import { Button } from 'antd'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useGetConferencesQuery } from '../../api/conference.api'
import { useGetEventUserQuery } from '../../api/eventUser.api'
import { useMyContext } from '../../context/context'
import useConf from '../../hooks/useConf'
import useLog from '../../hooks/useViewLog'
import Conferences from '../Conferences/Conferences'
import Companies from '../companies/Companies'
import Articles from './Articles'
import Confirmation from './Confirmation'
import Dates from './Dates'
import Dates2 from './Dates2'
import Informations from './Informations'
import Informations2 from './Informations2'
import Participation from './Participation'
import RepresentedBy from './RepresentedBy'
import Resume from './Resume'
import Typos from './Typos'
import Quotas from './Quotas'

const StepsContext = createContext(null)

export const useSteps = () => {
  const context = useContext(StepsContext)
  if (!context) {
    return {}
  }
  return context
}

const StepsContextProvider = () => {
  const [userId, setUserId] = useState()
  const [stepId, setStepId] = useState(-1)
  const [participation, setParticipation] = useState()
  const [hasFinished, setHasFinished] = useState(false)
  const [daysId, setDaysId] = useState([])
  const [articles, setArticles] = useState({})
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const myContext = useMyContext()
  const {
    showConferences,
    articlesActive,
    articlesHide,
    showInfo2,
    companiesShow,
    showParticipation,
    COMPANIES_LABEL,
    QUOTAS_SHOW,
  } = useConf(participation)

  const { data: user } = useGetEventUserQuery(myContext)
  const { data: user2 } = useGetEventUserQuery({ ...myContext, userId })
  const { data: conferences } = useGetConferencesQuery(myContext)

  const { settings, event, userId: myUserId } = myContext

  const isMe = useMemo(() => userId && userId === myUserId, [myUserId, userId])

  const isClosed = useMemo(() => settings?.FO_MEETING_REGISTER_END && new Date() > new Date(settings.FO_MEETING_REGISTER_END), [settings])

  useLog('VIEW_REGISTER')

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { FO_MEETING_CONFERENCES_LABEL, FO_MEETING_TYPO_IS_ACTIVE, FO_MEETING_INFO_2_LABEL, FO_MEETING_TYPO_LABEL } = settings

  const nextStep = useCallback(() => {
    setStepId(prev => prev + 1)
  }, [])
  const resetSteps = useCallback((p, u) => {
    setParticipation(p || null)
    setStepId(0)
    setUserId(u || null)
    // if (Array.isArray(event.days) && p === 5) {
    //   if (user?.UserEvent) {
    //     setDaysId(user?.UserEvent?.UserEventDays?.filter(ued => ued.registered)?.map(ued => ued.eventDayId))
    //   }
    // }
  }, [])

  const showInfo = useMemo(() => {
    return settings?.FO_MEETING_INFO_IS_ACTIVE
  }, [settings])

  const steps = useMemo(() => {
    return [
      ...(participation !== 5 ? [{ label: 'Participation', component: <Participation />, hide: !showParticipation }] : []),
      ...(participation === 2
        ? [
            { label: 'Invitation', component: <RepresentedBy /> },
            ...(showInfo && settings?.FO_MEETING_REPRESENTED_FORM
              ? [{ label: settings?.FO_MEETING_INFO_LABEL || 'Informations', component: <Informations /> }]
              : []),
          ]
        : []),
      ...(participation !== 2
        ? [
            ...(FO_MEETING_TYPO_IS_ACTIVE && participation !== 5
              ? [
                  {
                    label: FO_MEETING_TYPO_LABEL || 'Typologie',
                    component: <Typos />,
                    hide:
                      ((user2?.UserEvent?.typo || user2?.UserEvent?.typoId) && settings?.FO_MEETING_TYPO_NEXT_STEP) ||
                      (user2?.isExhibitor && settings?.FO_MEETING_TYPO_DEFAULT_EXHIBITOR),
                  },
                ]
              : []),
            ...(participation !== 5 ? [{ label: 'Choisir vos dates', component: <Dates />, hide: event?.days.length < 2 }] : []),
            ...(participation !== 5 && articlesActive
              ? [{ label: settings?.FO_MEETING_ARTICLES_LABEL || 'Articles', component: <Articles />, hide: articlesHide }]
              : []),
            ...(showInfo ? [{ label: settings?.FO_MEETING_INFO_LABEL || 'Informations', component: <Informations /> }] : []),
            ...(participation === 5 ? [{ label: 'Choisir vos dates', component: <Dates />, hide: event?.days.length < 2 }] : []),
            ...(participation === 5 && articlesActive
              ? [{ label: settings?.FO_MEETING_ARTICLES_LABEL || 'Articles', component: <Articles />, hide: articlesHide }]
              : []),
            ...(companiesShow ? [{ label: COMPANIES_LABEL || 'Sociétés', component: <Companies /> }] : []),
            ...(showConferences
              ? [{ label: FO_MEETING_CONFERENCES_LABEL || 'Conférences', component: <Conferences userId={userId} nextStep={nextStep} /> }]
              : []),
            ...(showInfo2 ? [{ label: FO_MEETING_INFO_2_LABEL || 'Formulaire', component: <Informations2 /> }] : []),
            ...(QUOTAS_SHOW ? [{ label: settings?.FO_MEETING_QUOTAS_LABEL || 'Quotas', component: <Quotas /> }] : []),
            { label: 'Choisir vos dates', component: <Dates2 />, hide: true },
          ]
        : []),
      { label: settings?.FO_MEETING_CONFIRMATION_LABEL || 'Confirmation', component: <Confirmation /> },
    ]
  }, [
    participation,
    showParticipation,
    FO_MEETING_TYPO_IS_ACTIVE,
    FO_MEETING_TYPO_LABEL,
    user2,
    settings,
    event,
    articlesActive,
    articlesHide,
    showInfo,
    companiesShow,
    COMPANIES_LABEL,
    showConferences,
    FO_MEETING_CONFERENCES_LABEL,
    userId,
    nextStep,
    showInfo2,
    FO_MEETING_INFO_2_LABEL,
    QUOTAS_SHOW,
  ])

  const totalSteps = useMemo(() => steps.length - 1, [steps])

  const step = useMemo(() => steps[stepId], [stepId, steps])
  const goToEnd = useCallback(() => setStepId(totalSteps), [totalSteps])
  const isEnd = useMemo(() => stepId === totalSteps, [stepId, totalSteps])

  useEffect(() => {
    if (isClosed) {
      goToEnd()
      setHasFinished(true)
    } else if (stepId === -1 && user) {
      if (user?.UserEvent?.responded || user?.UserEvent?.registered) {
        if (conferences?.length < 1 && settings?.CONFERENCE_MIN_1) {
          setStepId(0)
        } else {
          goToEnd()
          setHasFinished(true)
        }
      } else {
        setStepId(0)
      }
    }
  }, [conferences, goToEnd, isClosed, participation, settings, stepId, user])

  const NextButton = useCallback(
    props => {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 16 }}>
          {hasFinished ? <Button onClick={goToEnd}>Annuler</Button> : <div />}
          <Button type="primary" {...props}>
            Valider
          </Button>
        </div>
      )
    },
    [goToEnd, hasFinished]
  )

  const CompanionName = useMemo(
    () =>
      participation === 5 && user2 && stepId < totalSteps ? (
        <div
          style={{ color: 'black', fontSize: 16, marginBottom: 20, fontWeight: 500 }}
        >{`Vous inscrivez : ${user2.firstName} ${user2.lastName}`}</div>
      ) : null,
    [participation, stepId, totalSteps, user2]
  )

  const state = useMemo(
    () => ({
      setUserId,
      nextStep,
      userId,
      isMe,
      setParticipation,
      participation,
      goToEnd,
      resetSteps,
      NextButton,
      CompanionName,
      setDaysId,
      daysId,
      isEnd,
      articles,
      setArticles,
      isClosed,
      screenWidth,
    }),
    [nextStep, userId, isMe, participation, goToEnd, resetSteps, NextButton, CompanionName, daysId, isEnd, articles, isClosed, screenWidth]
  )

  return (
    <StepsContext.Provider value={state}>
      <div style={{ backgroundColor: '#F5F6F5', padding: 25 }}>
        <div style={{ backgroundColor: 'white', padding: 25 }}>
          <div
            style={{
              display: 'flex',
              alignItems: screenWidth >= 1000 && 'center',
              justifyContent: 'space-between',
              gap: 8,
              flexDirection: screenWidth < 1000 ? 'column' : 'row',
            }}
          >
            {!isEnd ? (
              <>
                <ul className="steps is-small" style={{ flexGrow: 1 }}>
                  {steps
                    .reduce((p, m) => {
                      const prev = p[p.length - 1]
                      if (prev && m.hide) {
                        return [...p, { ...m, number: prev.number }]
                      }
                      if (prev) {
                        return [...p, { ...m, number: prev.number + 1 }]
                      }
                      return [...p, { ...m, number: m.hide ? 0 : 1 }]
                    }, [])
                    .map(({ label, hide, number }, i) => {
                      if (hide) {
                        return null
                      }

                      return (
                        <li
                          className={`step-item ${stepId > i && 'is-completed is-success'} ${stepId === i && 'is-active'} ${stepId ===
                            steps.length && 'is-completed is-success'}`}
                          key={`step-${i}`}
                        >
                          <div className="step-marker">{number}</div>
                          <div className="step-details">
                            <p className="step-title">Étape {number}</p>
                            <p>{label}</p>
                          </div>
                        </li>
                      )
                    })}
                </ul>
                <Resume />
              </>
            ) : (
              <div />
            )}
          </div>
          <div>{step?.component}</div>
        </div>
      </div>
    </StepsContext.Provider>
  )
}

export default StepsContextProvider
